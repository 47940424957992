.modal-teacher {
  background-color: white;
  overflow-y: scroll !important;
  height: 100%;
  z-index: 999;
  max-width: 820px;
  left: 0;
  right: 0;
  /* top: 100px !important; */
  margin: 0 auto;
  position: absolute;
  padding-bottom: 20px;

  overflow: hidden;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.modal-teacher:focus {
    outline: 0;
}

@media screen and (min-width: 768px) {
  .modal-teacher {
    padding: 20px;
    border-radius: 20px;
    top: 50px !important;
    height: 85%;
  }

.teacher-desc li{
    line-height: 22.4px;
}
}

.ReactModal__Overlay {
  background-color: #0000005e !important;
}

.teacher-desc li{
    list-style-type: disc;
}