.order-modal {
  overflow-y: scroll;
  height: 100%;
  z-index: 999;
  max-width: 820px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  padding-bottom: 20px;
  background-color: #2d2d2d;
  /* overflow: hidden; */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.order-modal:focus {
  outline: 0;
}

@media screen and (min-width: 768px) {
  .order-modal {
    padding: 20px;
    border-radius: 20px;
    top: 104px !important;
    height: 90%;
    max-height: 825px;
    overflow-y: scroll;
    top: 50px !important;
  }
}
